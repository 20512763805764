<template>
  <div class="pa-9">
    <h1>Tạo combo khóa học 1-1</h1>
    <ValidationObserver ref="add_combo">
      <v-row>
        <v-col cols="6">
          <h4>Tên combo</h4>
          <ValidationProvider
              name="title"
              rules="required"
              v-slot="{ errors }"
          >
            <v-text-field
                outlined
                dense
                :name="'title'"
                v-model="combo.title"
                :placeholder="'Nhập tên combo'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <h4>Tên cấp độ</h4>
          <ValidationProvider
              name="title"
              rules="required"
              v-slot="{ errors }"
          >
            <v-text-field
                outlined
                dense
                :name="'level'"
                v-model="combo.level"
                :placeholder="'Nhập tên cấp độ'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <h4>Giá combo</h4>
          <ValidationProvider name="price" rules="required|number" v-slot="{ errors }">
            <vuetify-money
                v-model="combo.price"
                v-bind:label="''"
                v-bind:placeholder="'Nhập giá combo'"
                v-bind:readonly="false"
                v-bind:dense="true"
                v-bind:disabled="false"
                v-bind:outlined="true"
                v-bind:clearable="true"
                v-bind:valueWhenIsEmpty="''"
                v-bind:options="options"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] ? 'Giá combo không được để trống' : '' }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <h4>Số lượng buổi học</h4>
          <ValidationProvider
              name="number"
              rules="required"
              v-slot="{ errors }"
          >
            <v-text-field
                outlined
                dense
                :name="'title'"
                type="number"
                v-model="combo.number_lesson"
                :placeholder="'Nhập số lượng'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="combo.sale" class="mx-2" label="Giảm giá combo"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <h4>Giá sau khi giảm</h4>
          <vuetify-money
              v-model="combo.amount"
              v-bind:label="''"
              v-bind:placeholder="'Nhập giá combo'"
              v-bind:readonly="false"
              v-bind:dense="true"
              v-bind:disabled="false"
              v-bind:outlined="true"
              v-bind:clearable="true"
              v-bind:valueWhenIsEmpty="''"
              v-bind:options="options"
          />
        </v-col>
        <v-col cols="12">
          <CkContent
              :content="combo.description"
              @getData="(value) => combo.description = value"
          ></CkContent>
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="5" class="d-flex align-center justify-center text-center">
        <HnrButton color="primary"
                   width="200px"
                   @hnrClickEvent="addProduct"
                   class="text-body-1" :text="'Thêm combo'"/>
      </v-col>
    </v-row>
  </div>

</template>
<script>
import {SAVE_COMBO} from "@/store/combo-course.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      combo: {},
      options: {
        locale: "pt-BR",
        prefix: "VNĐ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    }
  },
  components: {
    HnrButton: () => import("@/components/elements/hnr-button"),
    CkContent: () => import( "@/components/ck-content"),
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    async addProduct() {
      let result = await this.$refs.add_combo.validate()
      if (result) {
        if (this.combo.sale) {
          if (!this.combo.amount) {
            this.$toasted.error('Bạn chưa nhập số tiền giảm giá', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
            return false;
          }
          else{
            if (parseFloat(this.combo.amount) > parseFloat(this.combo.price)) {
              this.$toasted.error('Số tiền giảm giá phải nhỏ hơn giá gốc !!!', {
                position: "top-right",
                theme: "toasted-primary",
                duration: 1000
              });
              return false;
            }
          }
        } else {
          this.combo.amount = 0
        }
        if (!this.combo.description) {
          this.$toasted.error('Bạn chưa nhập nội dung mô tả', {
            position: "top-right",
            duration: 1000
          });
          return false
        }
        this.$store.dispatch(SAVE_COMBO, this.combo).then((data) => {
          if (data.data.status == 1) {
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
            this.$router.push('/combo-course/list')
          }
        })
      }
    },
  }
}
</script>